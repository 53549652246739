<script setup lang="ts">
// vue
import { computed } from 'vue'

/**
 * options
 * ==================================================================
 */
defineOptions({
  inheritAttrs: false
})

/**
 * props
 * ==================================================================
 */
interface Props {
  validationErrors: Partial<Record<string, string>>
}
const props = withDefaults(defineProps<Props>(), {
  validationErrors: () => ({})
})

/**
 * computed
 * ==================================================================
 */
const hasErrors = computed(() => {
  return Boolean(Object.keys(props.validationErrors).length)
})
</script>

<template>
  <v-card
    v-if="hasErrors"
    v-bind="$attrs"
    color="red-lighten-1"
    density="compact"
  >
    <v-card-title class="d-flex align-center text-h6 pa-3">
      <v-icon start>
        mdi-alert-circle-outline
      </v-icon>
      Attention!
    </v-card-title>
    <v-divider color="white" />
    <v-list
      bg-color="red-lighten-1"
      density="compact"
    >
      <template
        v-for="(error, vid) in props.validationErrors"
        :key="vid"
      >
        <v-list-item class="text-body-2">
          <!-- eslint-disable vue/no-v-html -->
          &#8226; <span v-html="error" />
        </v-list-item>
      </template>
    </v-list>
  </v-card>
</template>
